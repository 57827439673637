#aboutus-card {
  border: 0;
  text-align: center;
}

#aboutus-card-img {
  width: 80%;
  aspect-ratio: 1;
  border-radius: 50%;
}

#aboutus-staff-img {
  width: 90%;
  border-radius: 10%;
}

.img-container {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}
.staff-img {
  transition: all 0.6s linear;
  width: 100%;
  border-radius: 50%;
}
.img-container:hover .staff-img {
  transform: scale(1.2);
}
