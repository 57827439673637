#my-navbar-header {
  position: relative;
  /* background: #6600cc; */
  /* color: white; */
  text-align: center;
  height: 80px;
}
#my-navbar-header h1 {
  text-align: center;
  line-height: 100px;
  margin-left: 100px;
  padding-bottom: 0;
}
html {
  /* this solved the problem that fixed header overlapping anchor bookmarks */
  scroll-padding-top: 50px; /* height of sticky header */
}

#mynav-link {
  color: white !important;
}
#mynav-link.active {
  color: var(--edc-dark) !important;
  background-color: var(--edc-light);
}
#mynav-link:hover {
  background-color: var(--edc-green) !important;
  color: var(--edc-dark) !important;
}

/* brand img sit at bottom of navbar, but taller than navbar */
#navbar-brand-img {
  position: absolute;
  bottom: 0px;
  padding: 5px;
  width: 120px;
  height: auto;
  transition: 0.5s;
}
/* small brand img for collapse */
#navbar-brand-img-sm {
  /* position: absolute; */
  bottom: 0px;
  /* padding: 5px; */
  width: 40px;
}
#navbar-brand-filler {
  display: inline-block;
  width: 120px;
}

/* remove caret in the dropdown button */
.dropdown-toggle::after {
  /* display: none !important; */
  display: inline;
}

#dropdown-menu {
  padding-top: 0%;
  background-color: var(--edc-green);
  margin-top: -2px; /* get rid of the gap between menu and dropdown menu */
  border: none;
  border-radius: 0%;
}
#dropdown-menu a:hover {
  background-color: var(--edc-light);
}
#dropdown-menu-right-align {
  right: 0;
  left: auto;
}

.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}
#lblCartCount {
  font-size: 0.8rem;
  background: #ff0000;
  color: #fff;
  padding: 0 5px;
  vertical-align: top;
  margin-left: -1px;
}

/* Footer style */
.nav-footer {
  background-color: #6600cc;
  font-size: 14px;
  color: white;
  border-top: 1px solid #e7e7e7;
  text-align: center;
  padding: 5px;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 30px;
  width: 100%;
}
