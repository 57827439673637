#service-card {
  background-color: inherit;
  text-align: center;
  align-items: center;
  border-width: 0;
}

.trans-img {
  transition: all 0.6s linear;
  width: 40px;
}
.trans-img:hover {
  transform: scale(1.2);
}
