.calendar-container {
  text-align: center;
}

.add-event-container {
  display: flex;
  width: 50%;
  margin: auto;
}

.calendar {
  margin: 100px 100px 1px auto;
  /* width: 500px; */
}

.vacant-event {
  background-color: lightskyblue;
  /* border: 0px !important; */
}
.vacant-event:hover {
  background-color: lightgreen;
}

.booked-event {
  background-color: green;
}

.landline-user {
  background-color: brown;
}

.rbc-event-label {
  display: none;
}
