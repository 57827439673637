.accordion-button {
  padding: 2px 10px !important;
}

.btn-timeslot {
  margin-left: 3px;
  margin-bottom: 10px;
  width: 5rem !important;
  font-size: medium !important;
  /* font-weight: bold !important; */
  border-radius: 3px !important;
  padding: 1px !important;
}
/* show two months vertically
.react-datepicker__month-container {
  float: none;
} */
