#video-container {
  position: relative;
}
#video-container video {
  position: relative;
  width: 100%;
}
#text-on-video {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #b4ff84;
  width: 100%;
  padding: 10px;
}

#section-service {
  background-color: seagreen;
  color: white;
}

#home-intro {
  color: seagreen;
}

#no-gap {
  position: fixed;
  bottom: 5px;
  right: 5px;
  padding-top: 15px;
  line-height: 1.3;
  width: 150px;
  height: 150px;
  color: white;
  background-color: #31d2f2;
  text-align: center;
  border-radius: 100px;
  font-size: large;
  font-weight: bold;
  z-index: 5;
  opacity: 0.85;
}
#no-gap-tcs {
  line-height: 1.5;
  font-size: small;
  font-weight: normal;
}
